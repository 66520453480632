import React from 'react';

import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import Quotes from '../components/Icons/Quotes';
import Smile from '../components/Icons/Smile';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { GraphqlImage } from '../utils/IPageProps';

import style from '../styles/pages/about.scss';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

interface Props {
  data: {
    aboutUsBeach: GraphqlImage;
  };
}

const AboutPage: React.FC<Props> = ({ data }) => {
  const handleButtonClick = () => {
    location.href = 'mailto:kontakt@fanind.pl';
  };

  return (
    <Layout isDarkTheme>
      <SEO title="About" />
      <section className={style.hero}>
        <div className="container">
          <Smile color="#fff" />
          <h2>
            Made by a team of researchers, analysts, and visionaries dedicated to making the
            financial world a more inviting and empowering space.
          </h2>
        </div>
      </section>
      <section className={style.callToAction}>
        <div className="container">
          <h2>We will elevate your marketing and help achieve critical exposure!</h2>
          <p>
            We are a talent group that mixes experience, market knowledge and technology to drive
            traffic and consumers to your business!
          </p>
          <PrimaryButton onClick={handleButtonClick}>Contact Us</PrimaryButton>
        </div>
      </section>
      <section className={style.darkSection}>
        <div className="container">
          <Img
            className={style.aboutUsBeach}
            fluid={data.aboutUsBeach.childImageSharp.fluid}
            alt="People talking by the ocean"
          />
          <Quotes />
          <h3>
            We connect consumers to your business plain and simple. Grow a base of raving fans that
            keep coming back.
          </h3>
        </div>
      </section>
    </Layout>
  );
};

export const indexQuery = graphql`
  {
    aboutUsBeach: file(relativePath: { eq: "AboutUs/about-us-beach.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default AboutPage;
