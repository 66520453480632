import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const Quotes = (props: IIconProps) => {
  const { width = 40, height = 30, className = '' } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.615 30c-2.666 0-4.769-.957-6.307-2.87C.769 25.214 0 22.726 0 19.664c0-4.115 1.077-7.895 3.23-11.34C5.488 4.785 8.82 2.01 13.23 0l4.308 3.589c-3.59 1.148-6.564 3.205-8.923 6.172-2.256 2.966-3.282 5.837-3.077 8.612l.462.144c.615-.861 1.795-1.292 3.538-1.292 1.539 0 2.923.574 4.154 1.722 1.231 1.149 1.847 2.68 1.847 4.594 0 1.722-.667 3.253-2 4.593C12.205 29.378 10.564 30 8.614 30zm22.462 0c-2.667 0-4.77-.957-6.308-2.87-1.538-1.915-2.307-4.403-2.307-7.465 0-4.115 1.076-7.895 3.23-11.34 2.257-3.54 5.59-6.315 10-8.325L40 3.589c-3.59 1.148-6.564 3.205-8.923 6.172-2.257 2.966-3.282 5.837-3.077 8.612l.462.144c.615-.861 1.794-1.292 3.538-1.292 1.538 0 2.923.574 4.154 1.722 1.23 1.149 1.846 2.68 1.846 4.594 0 1.722-.667 3.253-2 4.593C34.667 29.378 33.026 30 31.077 30z"
        fill="#00B7B7"
      />
    </svg>
  );
};

export default React.memo(Quotes);
